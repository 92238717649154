/*
    共通方法和共通变量写在这里
   */
const data = {
	ClassLists: [{ // 班级选项数量
		value: '1',
		label: '一班'
	}, {
		value: '2',
		label: '二班'
	}, {
		value: '3',
		label: '三班'
	}, {
		value: '4',
		label: '四班'
	}, {
		value: '5',
		label: '五班'
	}, {
		value: '6',
		label: '六班'
	}, {
		value: '7',
		label: '七班'
	}, {
		value: '8',
		label: '八班'
	}, {
		value: '9',
		label: '九班'
	}, {
		value: '10',
		label: '十班'
	}, {
		value: '11',
		label: '十一班'
	}, {
		value: '12',
		label: '十二班'
	}, {
		value: '13',
		label: '十三班'
	}, {
		value: '14',
		label: '十四班'
	}, {
		value: '15',
		label: '十五班'
	}, {
		value: '16',
		label: '十六班'
	}, {
		value: '17',
		label: '十七班'
	}, {
		value: '18',
		label: '十八班'
	}, {
		value: '19',
		label: '十九班'
	}, {
		value: '20',
		label: '二十班'
	}]
}

/* 
	将金额转换成以厘计算
 */
const getMoney = function(num) {
	if (typeof(num) === 'string') {
		return parseFloat(num) * 1000
	} else {
		return num * 1000
	}
}

/* 
	将金额转换成以厘计算
 */
const setMoney = function(num) {
	if (typeof(num) === 'string') {
		return parseFloat(num) / 1000
	} else {
		return num / 1000
	}
}

/* 
	将秒级时间戳转换成字符串格式日期
 */
const dateFormat = function(val) {
	val *= 1000;
	let date = new Date();
	date.setTime(val);
	let y = date.getFullYear();
	let m = date.getMonth() + 1;
	let d = date.getDate();
	let h = date.getHours();
	let f = date.getMinutes();
	let s = date.getSeconds();
  if(s < 10){
    s = "0"+s;
  }
	return y + '-' + m + '-' + d + ' ' + h + ':' + f + ':' + s;
}



export default {
	data,
	getMoney,
	setMoney,
	dateFormat
}
