import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const adminName = ' _ 视云格后台管理系统'


// 重写router的push方法，解决使用push跳转报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

const routes = [{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/index'),
		meta: {
			title: '登录' + adminName
		}
	},
	{
		path: '/login/screenindex',
		name: 'loginScreen',
		component: () => import('@/views/login/screenindex'),
		meta: {
			title: '登录'
		}
	},
	{
		path: '/advertising',
		name: 'advertising',
		component: () => import('@/views/Advertising/advertising.vue'),
		meta: {
			role: true
		},
		children: [{
				path: '/adverPlan/adverPlanAccount',
				name: 'adverPlanAccount',
				component: () => import('@/views/Advertising/adverPlanAccount.vue'),
				meta: {
					title: '我的账户'
				}
			},{
				path: '/adverPlan/certification',
				name: 'certification',
				component: () => import('@/views/Advertising/certification.vue'),
				meta: {
					title: '资质管理'
				}
			},{
				path: '/adverPlan/password',
				name: 'adPassword',
				component: () => import('@/views/Advertising/password.vue'),
				meta: {
					title: '密码修改'
				}
			},
			{
				path: '/advertising/addAdver',
				name: 'addAdver',
				component: () => import('@/views/Advertising/addAdver.vue'),
				meta: {
					title: '创建广告'
				}
			},
			{
				path: '/advertising/adverList',
				name: 'adverList',
				component: () => import('@/views/Advertising/adverList.vue'),
				meta: {
					title: '我的计划'
				}
			},
			{
				path: '/advertising/feedbackList/:advId',
				name: 'feedbackList',
				component: () => import('@/views/Advertising/feedbackList.vue'),
				meta: {
					title: '反馈记录'
				}
			},
		]
	},
	{
		path: '/',
		name: 'layout',
		component: () => import('@/views/index.vue'),
		meta: {
			role: true
		},
		children: [{
				path: 'SchoolHome',
				name: 'SchoolHome',
				component: () => import('@/views/SchoolHome/home.vue'),
				meta: {
					title: '首页' + adminName
				}
			},
			{
				path: 'teacherHome/:ClassID?/:ClassType?',
				name: 'teacherHome',
				component: () => import('@/views/TeacherHome/home.vue'),
				meta: {
					title: '首页' + adminName
				}
			},
			{
				path: 'AgentHome',
				name: 'AgentHome',
				component: () => import('@/views/AgentHome/AgentHome.vue'),
				meta: {
					title: '首页' + adminName
				}
			},
			{
				path: 'ClassList',
				name: 'ClassList',
				component: () => import('@/views/School_ClassManage/ClassList.vue'),
				meta: {
					title: '班级列表' + adminName
				}
			},
			{
				path: 'classHistory',
				name: 'classHistory',
				component: () => import('@/views/School_ClassManage/classHistory.vue'),
				meta: {
					title: '入离校记录' + adminName
				}
			},
			{
				path: 'studentHistory',
				name: 'studentHistory',
				component: () => import('@/views/School_ClassManage/studentHistory.vue'),
				meta: {
					title: '班级记录' + adminName
				}
			},
			{
				path: 'detail',
				name: 'Detail',
				component: () => import('@/views/School_ClassManage/Detail.vue'),
				meta: {
					title: '详细记录' + adminName
				}
			},
			{
				path: 'historyClass',
				name: 'historyClass',
				component: () => import('@/views/School_ClassManage/historicalClass.vue'),
				meta: {
					title: '历史班级' + adminName
				}
			},
			{
				path: 'CreateGrade',
				name: 'CreateGrade',
				component: () => import('@/views/School_ClassManage/CreateGrade.vue'),
				meta: {
					title: '创建年级' + adminName
				}
			},
			{
				path: 'dataInfo',
				name: 'dataInfo',
				component: () => import('@/views/Teacher_dataInfo/dataInfo.vue'),
				meta: {
					title: '数据管理' + adminName
				}
			},
			{
				path: 'teacherList',
				name: 'teacherList',
				component: () => import('@/views/School_TeacherAdmin/TeacherList.vue'),
				meta: {
					title: '教师列表' + adminName
				}
			},
			{
				path: 'arriveLeaveHistory',
				name: 'arriveLeaveHistory',
				component: () => import('@/views/SchoolSetting/arriveLeaveHistory.vue'),
				meta: {
					title: '教职工出入记录' + adminName
				}
			},
			{
				path: 'staff-history/:date',
				name: 'StaffHistory',
				component: () => import('@/views/SchoolSetting/StaffHistory.vue'),
				meta: {
					title: '教职工出入信息' + adminName
				}
			},
			{
				path: 'setTime',
				name: 'setTime',
				component: () => import('@/views/SchoolSetting/setTime.vue'),
				meta: {
					title: '到校离校时间' + adminName
				}
			},
			{
				path: 'set-holiday-time',
				name: 'SetHolidayTime',
				component: () => import('@/views/SchoolSetting/SetHolidayTime.vue'),
				meta: {
					title: '节假日设置' + adminName
				}
			},
			{
				path: 'create-campus',
				name: 'createCampus',
				component: () => import('@/views/SchoolSetting/createCampus.vue'),
				meta: {
					title: '创建校区' + adminName
				}
			},
			{
				path: 'addTeacher',
				name: 'addTeacher',
				component: () => import('@/views/School_TeacherAdmin/AddTeacher.vue'),
				meta: {
					title: '添加教师' + adminName
				}
			},
			{
				path: 'studentList',
				name: 'studentList',
				component: () => import('@/views/StudentAdmin/StudentList.vue'),
				meta: {
					title: '学生列表' + adminName
				}
			},
			{
				path: 'eyetestList',
				name: 'eyetestList',
				component: () => import('@/views/StudentAdmin/eyetestList.vue'),
				meta: {
					title: '数据列表' + adminName
				}
			},
			{
				path: 'patriarch-detail/:studentId',
				name: 'PatriarchDetail',
				component: () => import('@/views/StudentAdmin/PatriarchDetail.vue'),
				meta: {
					title: '家长实名列表' + adminName
				}
			},
			{
				path: 'applyList',
				name: 'applyList',
				component: () => import('@/views/StudentAdmin/applyList.vue'),
				meta: {
					title: '申请列表' + adminName
				}
			},
			{
				path: 'articleRegulate',
				name: 'articleRegulate',
				component: () => import('@/views/Article/articleRegulate.vue'),
				meta: {
					title: '我的文章' + adminName
				}
			},
			{
				path: 'WriteArticle',
				name: 'WriteArticle',
				component: () => import('@/views/Article/WriteArticle.vue'),
				meta: {
					title: '创作文章' + adminName
				}
			},
			{
				path: 'operationLog',
				name: 'operationLog',
				component: () => import('@/views/System_Settings/operationLog.vue'),
				meta: {
					title: '操作日志' + adminName
				}
			},
			{
				path: 'testCount',
				name: 'testCount',
				component: () => import('@/views/security/testCount.vue'),
				meta: {
					title: '检测数量' + adminName
				}
			},
			{
				path: 'securityHistory',
				name: 'securityHistory',
				component: () => import('@/views/security/securityHistory.vue'),
				meta: {
					title: '安防记录' + adminName
				}
			},
			{
				path: 'agentAcceptGet',
				name: 'agentAcceptGet',
				component: () => import('@/views/Agent_MyAccept/agentAcceptGet.vue'),
				meta: {
					title: '收益提现' + adminName
				}
			},
			{
				path: 'agentAcceptList',
				name: 'agentAcceptList',
				component: () => import('@/views/Agent_MyAccept/agentAcceptList.vue'),
				meta: {
					title: '提现记录' + adminName
				}
			},
			{
				path: 'safetySetting',
				name: 'safetySetting',
				component: () => import('@/views/System_Settings/safetySetting.vue'),
				meta: {
					title: '个人设置' + adminName
				}
			},
			{
				path: 'personalCenter',
				name: 'personalCenter',
				component: () => import('@/views/TeacherSettings/personal_center.vue'),
				meta: {
					title: '个人中心' + adminName
				}
			},
			{
				path: 'personalSetting',
				name: 'personalSetting',
				component: () => import('@/views/TeacherSettings/personal_setting.vue'),
				meta: {
					title: '个人设置' + adminName
				},
				children: [{
						path: 'BasicSetting',
						name: 'BasicSetting',
						component: () => import('@/views/TeacherSettings/BasicSetting.vue'),
						meta: {
							title: '基本设置' + adminName
						}
					},
					{
						path: 'safetySetting',
						name: 'safetySet',
						component: () => import('@/views/TeacherSettings/safetySetting.vue'),
						meta: {
							title: '安全设置' + adminName
						}
					},
					{
						path: 'accountBind',
						name: 'accountBind',
						component: () => import('@/views/TeacherSettings/accountBind.vue'),
						meta: {
							title: '账号绑定' + adminName
						}
					},
					{
						path: '/personalSetting',
						redirect: '/personalSetting/BasicSetting'
					}
				]
			},
			{
				path: 'centerDetail',
				name: 'centerDetail',
				component: () => import('@/views/TeacherSettings/centerDetail.vue'),
				meta: {
					title: '详情' + adminName
				}
			},
			{
				path: 'eyesight/:StuID?',
				name: 'eyesight',
				component: () => import('@/views/StudentAdmin/visualChart .vue'),
				meta: {
					title: '学生视力表' + adminName
				}
			},
			{
				path: 'facilityList/:page?', // 代理设备列表
				name: 'facilityList',
				component: () => import('@/views/Agent_safety/facilityList.vue'),
				meta: {
					title: '设备列表' + adminName
				}
			},
			{
				path: 'cameraList/:machineId?', // 代理设备列表
				name: 'cameraList',
				component: () => import('@/views/Agent_safety/cameraList.vue'),
				props: true,
				meta: {
					title: '摄像头列表' + adminName
				}
			},
			{
				path: 'facilitydetail/:detailInfo?/:type?/:page?', // 代理设备详情
				name: 'facilitydetail',
				component: () => import('@/views/Agent_safety/facilitydetail.vue'),
				meta: {
					title: '设备详情' + adminName
				}
			},
			{
				path: 'facilitydetailYsp/:detailInfo?/:type?/:page?', // 代理设备详情音视频
				name: 'facilitydetailYsp',
				component: () => import('@/views/Agent_safety/facilitydetailYsp.vue'),
				meta: {
					title: '设备详情' + adminName
				}
			},
			{
				path: 'facilitydetailXyws/:detailInfo?/:type?', // 代理设备详情校园卫士
				name: 'facilitydetailXyws',
				component: () => import('@/views/Agent_safety/facilitydetailXyws.vue'),
				meta: {
					title: '设备详情' + adminName
				}
			},
			{
				path: 'facilitydetailYgy/:detailInfo?/:type?', // 代理设备详情验光仪
				name: 'facilitydetailYgy',
				component: () => import('@/views/Agent_safety/facilitydetailYgy.vue'),
				meta: {
					title: '设备详情' + adminName
				}
			},
			{
				path: 'safetyrelieve', // 代理解除设备
				name: 'safetyrelieve',
				component: () => import('@/views/Agent_safety/safetyrelieve.vue'),
				meta: {
					title: '解除设备' + adminName
				}
			},
			{
				path: 'Agent_SchoolList', // 代理学校列表
				name: 'Agent_SchoolList',
				component: () => import('@/views/Agent_SchoolNum/Agent_SchoolList.vue'),
				meta: {
					title: '代理学校列表' + adminName
				}
			},
			{
				path: 'Agent_ShopList', // 代理门店设备列表
				name: 'Agent_ShopList',
				component: () => import('@/views/Agent_ShopNum/Agent_ShopList.vue'),
				meta: {
					title: '代理门店设备列表' + adminName
				}
			},
			{
				path: 'Agent_ZzdList', // 自助点设备列表
				name: 'Agent_ZzdList',
				component: () => import('@/views/Agent_ZzdNum/Agent_ZzdList.vue'),
				meta: {
					title: '自助点设备列表' + adminName
				}
			},
			{
				path: 'SchoolList_detail/:Schinfos?', // 代理学校列表-详情
				name: 'SchoolList_detail',
				component: () => import('@/views/Agent_SchoolNum/SchoolList_detail.vue'),
				meta: {
					title: '设备列表' + adminName
				}
			},
			{
				path: 'ShopList_detail/:Schinfos?', // 代理门店列表-详情
				name: 'ShopList_detail',
				component: () => import('@/views/Agent_ShopNum/ShopList_detail.vue'),
				meta: {
					title: '设备列表' + adminName
				}
			},
			{
				path: 'ZzdList_detail/:Schinfos?', // 自助点列表-详情
				name: 'ZzdList_detail',
				component: () => import('@/views/Agent_ZzdNum/ZzdList_detail.vue'),
				meta: {
					title: '设备列表' + adminName
				}
			},
			{
				path: 'Agent_enterSchool', // 代理进驻学校
				name: 'Agent_enterSchool',
				component: () => import('@/views/Agent_SchoolNum/Agent_enterSchool.vue'),
				meta: {
					title: '添加学校' + adminName
				}
			},
			{
				path: 'Agent_StudentAdd', // 代理添加学生
				name: 'Agent_StudentAdd',
				component: () => import('@/views/Agent_SchoolNum/Agent_StudentAdd.vue'),
				meta: {
					title: '添加学生' + adminName
				}
			},
			{
				path: 'holiday-setting',
				name: 'HolidaySetting',
				component: () => import('@/views/Agent_SchoolNum/HolidaySetting.vue'),
				meta: {
					title: '节假日设置' + adminName
				}
			},
			{
				path: 'holiday-template',
				name: 'HolidayTemplate',
				component: () => import('@/views/Agent_SchoolNum/HolidayTemplate.vue'),
				meta: {
					title: '假日模板' + adminName
				},
			},
			{
				path: 'health_list', // 健康列表
				name: 'health_list',
				component: () => import('@/views/health/health_list.vue'),
				meta: {
					title: '健康列表' + adminName
				}
			},
			{
				path: 'health_detail/:student_id?', // 眼睛健康详情
				name: 'health_detail',
				component: () => import('@/views/health/health_detail.vue'),
				meta: {
					title: '眼睛健康详情' + adminName
				}
			},
			{
				path: 'health_update/:student_id?', // 更新信息
				name: 'health_update',
				component: () => import('@/views/health/health_update.vue'),
				meta: {
					title: '更新信息' + adminName
				}
			},
			{
				path: 'healthlogList', // 学生健康日志
				name: 'healthlogList',
				component: () => import('@/views/Agent_log/healthlogList.vue'),
				meta: {
					title: '学生健康日志' + adminName
				}
			},
			{
				path: 'loginlogList', // 登陆日志
				name: 'loginlogList',
				component: () => import('@/views/Agent_log/loginlogList'),
				meta: {
					title: '登陆日志' + adminName
				}
			},
			{
				path: 'machinelogList', // 设备日志
				name: 'machinelogList',
				component: () => import('@/views/Agent_log/machinelogList.vue'),
				meta: {
					title: '设备日志' + adminName
				}
			},
			{
				path: 'schoollogList', // 学校日志
				name: 'schoollogList',
				component: () => import('@/views/Agent_log/schoollogList'),
				meta: {
					title: '学校日志' + adminName
				}
			},
			{
				path: 'health_history', // 单条历史数据
				name: 'health_history',
				component: () => import('@/views/health/health_history.vue'),
				meta: {
					title: '单条历史数据' + adminName
				}
			},
			{
				path: 'daily_revenue', // 每日收益
				name: 'daily_revenue',
				component: () => import('@/views/financial_management/daily_revenue.vue'),
				meta: {
					title: '每日收益' + adminName
				}
			},
			{
				path: 'revenue_details/:date?', // 每日收益详情
				name: 'revenue_details',
				component: () => import('@/views/financial_management/revenue_details.vue'),
				meta: {
					title: '每日收益详情' + adminName
				}
			},
			{
				path: 'revenue_withdrawal',
				name: 'revenue_withdrawal',
				component: () => import('@/views/financial_management/revenue_withdrawal.vue'),
				meta: {
					title: '提现记录' + adminName
				}
			},
			{
				path: 'school_income', // 学校每日收益
				name: 'school_income',
				component: () => import('@/views/financial_management/school_income.vue'),
				meta: {
					title: '学校每日收益' + adminName
				}
			},
			{
				path: 'school_details/:schoolid?/:datetype?/:SchoolName?', // 学校每日收益详情
				name: 'school_details',
				component: () => import('@/views/financial_management/school_details.vue'),
				meta: {
					title: '学校每日收益详情' + adminName
				}
			},
			{
				path: 'setting_modification', // 设置
				name: 'setting_modification',
				component: () => import('@/views/setting/setting_modification'),
				meta: {
					title: '设置' + adminName
				}
			},
			{
				path: 'user_information', // 用户信息
				name: 'user_information',
				component: () => import('@/views/setting/user_information'),
				meta: {
					title: '用户信息' + adminName
				}
			},
			{
				path: 'studetail/:StuID?', // 学生详细信息
				name: 'studetail',
				component: () => import('@/views/StudentAdmin/studetail.vue'),
				meta: {
					title: '学生详细信息' + adminName
				}
			},
			{
				path: 'studeupdate/:StuID?', // 学生信息修改
				name: 'studeupdate',
				component: () => import('@/views/StudentAdmin/studeupdate.vue'),
				meta: {
					title: '学生信息修改' + adminName
				}
			},
			{
				path: 'guaapplylist/:StuID?', // 家长申请列表
				name: 'guaapplylist',
				component: () => import('@/views/StudentAdmin/guaapplyList.vue'),
				meta: {
					title: '家长申请列表' + adminName
				}
			},
			{
				path: 'shealth_history/:StuID?', // 健康历史
				name: 'shealth_history',
				component: () => import('@/views/StudentAdmin/health_history.vue'),
				meta: {
					title: '健康历史' + adminName
				}
			},
			{
				path: "equipment_management/", // 设备数据管理
				name: "equipment_management",
				component: () => import("@/views/management/equipment_list.vue"),
				meta: {
					title: "设备数据管理" + adminName
				}
			},
			{
				path: "equipment_schools_detail/:date?/:type?", // 某天所有学校总数据
				name: "equipment_schools_detail",
				component: () => import("@/views/management/equipment_detail.vue"),
				meta: {
					title: "某天所有学校总数据" + adminName
				}
			},
			{
				path: "equipment_day_detail/:date?/:type?/:school_id?", // 某天所有学校总数据
				name: "equipment_day_detail",
				component: () => import("@/views/management/equipment_day_detail.vue"),
				meta: {
					title: "某学校某天设备数据" + adminName
				}
			},
			{
				path: "school_management/", //学校数据管理
				name: "school_management",
				component: () => import("@/views/management/school_list.vue"),
				meta: {
					title: "学校数据管理" + adminName
				}
			},
			{
				path: "school_management_detail/:school_id?/:type?", //某学校某几天数据
				name: "school_management_detail",
				component: () => import("@/views/management/school_detail.vue"),
				meta: {
					title: "某学校某几天数据" + adminName
				}
			},
			{
				path: "school_day_detail/:school_id?/:type?/:date?", //某学校某几天数据
				name: "school_day_detail",
				component: () => import("@/views/management/school_day_detail.vue"),
				meta: {
					title: "某学校某天数据" + adminName
				}
			}, {
				path: 'set_user',
				name: 'set_user',
				component: () => import('@/views/setting/set_user.vue'),
				meta: {
					title: '个人设置' + adminName
				}
			},
			{
				path: 'operation_log',
				name: 'operation_log',
				component: () => import('@/views/setting/operation_log.vue'),
				meta: {
					title: '操作记录' + adminName
				}
			},
			{
				path: 'edit_pass',
				name: 'edit_pass',
				component: () => import('@/views/setting/edit_pass.vue'),
				meta: {
					title: '账号密码' + adminName
				}
			},
			{
				path: 'edit_tel',
				name: 'edit_tel',
				component: () => import('@/views/setting/edit_tel.vue'),
				meta: {
					title: '绑定手机' + adminName
				}
			},
			{
				path: 'studeupdateeye/:StuID?', // 学生眼睛信息修改
				name: 'studeupdateeye',
				component: () => import('@/views/StudentAdmin/studeupdateeye.vue'),
				meta: {
					title: '学生视力信息修改' + adminName
				}
			},
			// {
			//   path: "classdataList",
			//   name: "classdataList",
			//   component: () => import("@/views/SchoolAdmin/classdata.vue"),
			//   meta: {
			//     title: "班级数据"
			//   }
			// },
			// {
			//   path: 'classStudentDataList/:gradeId?/:classId?',
			//   name: 'classStudentDataList',
			//   component: () => import('@/views/SchoolAdmin/classStudentsData.vue'),
			//   meta: {
			//     title: '班级数据'
			//   }
			// },
			// {
			//   path: 'classStudentDetail/:StuID?/:StuName?', // 数据管理-学生详细信息
			//   name: 'classStudentDetail',
			//   component: () => import('@/views/SchoolAdmin/classStudentDetail.vue'),
			//   meta: {
			//     title: '学生详细信息'
			//   }
			// },
			{
				path: 'hisclassStudentsData/:cid?/:oyear?',
				name: 'hisclassStudentsData',
				component: () => import('@/views/SchoolAdmin/hisclassStudentsData.vue'),
				meta: {
					title: '学生' + adminName
				}
			},
			{
				path: 'hisclassdataList',
				name: 'hisclassdataList',
				component: () => import('@/views/SchoolAdmin/hisclassdata.vue'),
				meta: {
					title: '测温数据' + adminName
				}
			},
			{
				path: 'testDetails',
				name: 'testDetails',
				component: () => import('@/views/SchoolAdmin/testDetails.vue'),
				meta: {
					title: '检测详情' + adminName
				}
			},
			{
				path: 'detail',
				name: 'detail',
				component: () => import('@/views/SchoolAdmin/detail.vue'),
				meta: {
					title: '学生体温' + adminName
				}
			},
			{
				path: 'measurementData',
				name: 'measurementData',
				component: () => import('@/views/SchoolAdmin/measurementData.vue'),
				meta: {
					title: '历史数据' + adminName
				}
			},
			// {
			//   path: 'studeupdateeye/:StuID?', // 学生眼睛信息修改
			//   name: 'studeupdateeye',
			//   component: () => import('@/views/StudentAdmin/studeupdateeye.vue'),
			//   meta: {
			//     title: '学生视力信息修改'
			//   }
			// },
			{
				path: 'classdataList',
				name: 'classdataList',
				component: () => import('@/views/SchoolAdmin/classdata.vue'),
				meta: {
					title: '数据列表' + adminName
				}
			},
			{
				path: 'classStudentDataList/:gradeId?/:classId?',
				name: 'classStudentDataList',
				component: () => import('@/views/SchoolAdmin/classStudentsData.vue'),
				meta: {
					title: '学生数据' + adminName
				}
			},
			{
				path: 'classStudentDetail/:StuID?/:StuName?', // 数据管理-学生详细信息
				name: 'classStudentDetail',
				component: () => import('@/views/SchoolAdmin/classStudentDetail.vue'),
				meta: {
					title: '学生详细信息' + adminName
				}
			},
			{
				path: "school_list",
				name: "school_list",
				component: () => import("@/views/Agent_SchoolNum/school_list.vue"),
				meta: {
					title: "学校管理-学校列表" + adminName
				}
			},
			{
				path: "shop_list",
				name: "shop_list",
				component: () => import("@/views/Agent_ShopNum/shop_list.vue"),
				meta: {
					title: "门店管理-门店列表" + adminName
				}
			},
			{
				path: "zzd_list",
				name: "zzd_list",
				component: () => import("@/views/Agent_ZzdNum/zzd_list.vue"),
				meta: {
					title: "自助点管理-自助点列表" + adminName
				}
			},
			{
				path: "classStudentDataListTea",
				name: "classStudentDataListTea",
				component: () => import("@/views/TeacherAdmin/classStudentsDataTea.vue"),
				meta: {
					title: "视力监测" + adminName
				}
			},
			{
				path: "classStudentMeasurementData",
				name: "classStudentMeasurementData",
				component: () => import("@/views/TeacherAdmin/classStudentMeasurementData.vue"),
				meta: {
					title: "测温数据" + adminName
				}
			},
			{
				path: "testDetailss",
				name: "testDetailss",
				component: () => import("@/views/TeacherAdmin/testDetailss.vue"),
				meta: {
					title: "学生详情" + adminName
				}
			},
			{
				path: 'classStudentDetailTea/:StuID?/:StuName?', // 视力监测-视力详细信息
				name: 'classStudentDetailTea',
				component: () => import('@/views/TeacherAdmin/classStudentDetailTea.vue'),
				meta: {
					title: '视力详细信息' + adminName
				}
			},
			{
				path: 'thealth_history/:StuID?', // 视力监测-健康历史
				name: 'thealth_history',
				component: () => import('@/views/TeacherAdmin/health_history.vue'),
				meta: {
					title: '健康历史' + adminName
				}
			},
			{
				path: 'publishArticles', // 发布文章
				name: 'publishArticles',
				component: () => import('@/views/TeacherAdmin/publishArticles.vue'),
				meta: {
					title: '发布文章' + adminName
				}
			},
			// {
			//   path: "classStudentDataListTea/:gradeId?/:classId?",
			//   name: "classStudentDataListTea",
			//   component: () => import("@/views/TeacherAdmin/classStudentsDataTea.vue"),
			//   meta: {
			//     title: "学生数据"
			//   }
			// },


		]
	}

]

const router = new VueRouter({
	routes
})

export default router
